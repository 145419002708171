<template>
  <v-container fluid>
    <base-page-title>
      <div>
        SVG Drawing: Fixture Design {{ partNumber }} - Device Under Test
        {{ dutConfigs.name }}
      </div>
    </base-page-title>
    <v-sheet outlined class="my-3 pb-5">
      <v-toolbar dense flat class="mt-5">
        <v-row>
          <v-col cols="12" class="d-flex justify-start align-start">
            <v-btn
              large
              color="secondary"
              class="mr-5"
              @click="
                downloadSVG(
                  0,
                  btnToggle == 1 ? 'probe' : 'pressure',
                  sideToggle == 1 ? 'top' : 'bottom'
                )
              "
              >Download current SVG View</v-btn
            >
            <v-btn large color="secondary" class="mr-10" @click="SVGdownloader"
              >Download All SVG Views</v-btn
            >
            <v-btn large color="warning" class="mr-5" @click="downloadImage"
              >Download current PDF View</v-btn
            >
            <v-btn large color="warning" class="mr-5" @click="downloadAllImage"
              >Download All PDF Views</v-btn
            >
            <v-spacer />
            <v-btn large color="secondary" @click="gotoConfigure"
              >View Fixture Design<v-icon small class="ml-1">
                mdi-open-in-new
              </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar>
      <v-toolbar dense flat class="mt-10 ml-5">
        <v-row>
          <v-col cols="6" class="text-center">
            <h4>Component Scaling</h4>
            <v-slider
              key="slider1"
              v-model="slider"
              step="10"
              ticks
              thumb-size="24"
              thumb-color="warning"
              thumb-label
              append-icon="mdi-magnify-plus-outline"
              prepend-icon="mdi-magnify-minus-outline"
              @click:append="zoomIn"
              @click:prepend="zoomOut"
              @input="sliderZoom"
            ></v-slider>
          </v-col>
          <v-col cols="6" class="d-flex justify-space-between">
            <v-btn-toggle
              v-model="btnRenderSide"
              mandatory
              borderless
              color="primary"
              @change="transformRender(btnRenderSide)"
            >
              <v-btn class="subtitle-1 font-weight-bold"> Original View </v-btn>
              <v-btn class="subtitle-1 font-weight-bold">
                Flip Horizontally
              </v-btn>
              <v-btn class="subtitle-1 font-weight-bold">
                Flip Vertically
              </v-btn>
            </v-btn-toggle>
            <v-spacer />
            <v-btn-toggle
              v-model="btnToggle"
              mandatory
              borderless
              color="primary"
            >
              <v-btn value="1" text> Probe Plate </v-btn>

              <v-btn value="2" text> Pressure Plate </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row v-if="btnToggle == 1" class="mt-3">
        <v-col cols="12" class="d-flex justify-end align-end">
          <v-menu
            offset-y
            v-for="(receptacle, index) in receptacleColorGroup"
            :key="`r${index}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="receptacle.color"
                v-bind="attrs"
                v-on="on"
                class="mr-2 font-weight-bold"
              >
                {{ receptacle.part_number }}
              </v-btn>
            </template>
            <v-color-picker
              dot-size="25"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hexa"
              show-swatches
              swatches-max-height="200"
              @input="
                updateReceptacle({
                  receptaclePk: receptacle.pk,
                  index,
                  event: $event,
                })
              "
            ></v-color-picker>
          </v-menu>
          <v-divider v-if="probeColorGroup.length > 0" vertical class="mx-5" />
          <v-menu
            offset-y
            v-for="(probe, index) in probeColorGroup"
            :key="`p${index}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="probe.color"
                v-bind="attrs"
                v-on="on"
                class="mr-2 font-weight-bold"
              >
                {{ probe.part_number }}
              </v-btn>
            </template>
            <v-color-picker
              dot-size="25"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              show-swatches
              @input="updateProbe({ probePk: probe.pk, index, event: $event })"
            ></v-color-picker>
          </v-menu>
          <v-divider
            v-if="guidepinColorGroup.length > 0"
            vertical
            class="mx-5"
          />
          <v-menu
            offset-y
            v-for="(guidepin, index) in guidepinColorGroup"
            :key="`gp${index}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="guidepin.color"
                v-bind="attrs"
                v-on="on"
                class="mr-2 font-weight-bold"
              >
                {{ guidepin.part_number }}
              </v-btn>
            </template>
            <v-color-picker
              dot-size="25"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              show-swatches
              @input="
                updateGuidepin({
                  guidepinPk: guidepin.pk,
                  index,
                  event: $event,
                })
              "
            ></v-color-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-else class="mt-3">
        <v-col class="d-flex justify-end align-end mr-5">
          <v-menu
            offset-y
            v-for="(pressurepin, index) in pressurepinColorGroup"
            :key="`pp${index}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="pressurepin.color"
                v-bind="attrs"
                v-on="on"
                class="mr-2 font-weight-bold"
              >
                {{ pressurepin.part_number }}
              </v-btn>
            </template>
            <v-color-picker
              dot-size="25"
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              mode="hexa"
              show-swatches
              swatches-max-height="200"
              @input="
                updatePressurepin({
                  pressurepinPk: pressurepin.pk,
                  index,
                  event: $event,
                })
              "
            ></v-color-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense class="mt-10">
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <v-switch
            v-model="showRefs"
            label="Show Refs"
            class="mt-2 ml-8"
            color="primary"
            @change="searchRefString = null"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-space-between">
          <v-text-field
            v-if="showRefs"
            v-model="searchRefString"
            label="Type filter text..."
            color="primary"
            single-line
            dense
            hide-details
            clearable
            class="mb-4"
            @click:clear="searchRefString = null"
          >
            <template v-slot:prepend>
              <v-icon color="warning">mdi-magnify</v-icon>
            </template></v-text-field
          >
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <v-btn-toggle
            v-model="sideToggle"
            mandatory
            borderless
            color="primary"
            class="mr-5"
          >
            <v-btn value="1" text ref="sideToggleTop"> Top </v-btn>

            <v-btn value="2" text ref="sideToggleBottom"> Bottom </v-btn>
          </v-btn-toggle>
          <v-checkbox
            v-model="showPlate"
            label="Show Plate"
            color="primary"
            dense
            hide-details
            class="mx-3 mt-2"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="showRefs" dense>
        <v-col cols="4" offset-md="2" class="text-center">
          <h4>REF Text Scaling</h4>
          <v-slider
            key="slider2"
            v-model="refSlider"
            step="10"
            ticks
            thumb-size="24"
            thumb-color="warning"
            thumb-label
            append-icon="mdi-magnify-plus-outline"
            prepend-icon="mdi-magnify-minus-outline"
            @click:append="refZoomIn"
            @click:prepend="refZoomOut"
            @change="sliderRefZoom"
          ></v-slider>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet>
      <svg
        version="1.1"
        id="svg1"
        xml:space="preserve"
        width="1588"
        height="1122.6667"
        viewBox="0 0 1588 1122.6667"
        sodipodi:docname="DEV260 Components Drawing Template v1.svg"
        inkscape:version="1.1 (c68e22c387, 2021-05-23)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        ref="svg_drawing"
      >
        <DrawingDefs />
        <DrawingGroup
          ref="drawing_group"
          :testpointPlot="testPoints"
          :testpointPlotter="testpointPlot"
          :guidePins="guidepinPlot"
          :pressurePins="pressurepinPlot"
          :receptacleGroup="receptacleGroup"
          :probeGroup="probeGroup"
          :guidepinGroup="guidepinGroup"
          :pressurepinGroup="pressurepinGroup"
          :renderScale="renderScale"
          :svgScale="svgScale"
          :btnToggle="+btnToggle"
          :sideToggle="+sideToggle"
          :btnRenderSide="+btnRenderSide"
          :project="project"
          :fixtureConfigId="fixtureConfigId"
          :sliderScale="sliderScale"
          :probeElement="probeElement"
          :showRefs="showRefs"
          :showPlate="showPlate"
          :searchRefString="searchRefString"
          :refSlider="sliderRefZoom"
          @toggleHoverText="toggleHoverText"
        />
      </svg>
    </v-sheet>
    <ViewCoordinates
      :svgElement="svgElement"
      :probeElement="probeElement[fixtureConfigId - 1]"
    />
    <v-snackbar v-model="snack" color="primary" bottom max-width="800">
      <v-container class="title py-0">
        <v-row dense>
          <v-col class="d-flex justify-center">
            <div>
              {{ hoverText }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
    <svg
      version="1.1"
      id="svg2"
      xml:space="preserve"
      width="1588"
      height="1122.6667"
      viewBox="0 0 1588 1122.6667"
      sodipodi:docname="DEV260 Components Drawing Template v1.svg"
      inkscape:version="1.1 (c68e22c387, 2021-05-23)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      ref="svg_drawing2"
      v-show="false"
    >
      <DrawingDefs />
      <DrawingGroup
        ref="drawing_group"
        :testpointPlot="testPoints"
        :testpointPlotter="testpointPlot"
        :guidePins="guidepinPlot"
        :pressurePins="pressurepinPlot"
        :receptacleGroup="receptacleGroup"
        :probeGroup="probeGroup"
        :guidepinGroup="guidepinGroup"
        :pressurepinGroup="pressurepinGroup"
        :renderScale="renderScale"
        :svgScale="svgScale"
        :btnToggle="+btnToggle"
        :sideToggle="2"
        :btnRenderSide="+btnRenderSide"
        :project="project"
        :fixtureConfigId="fixtureConfigId"
        :sliderScale="sliderScale"
        :probeElement="probeElement"
        :showRefs="showRefs"
        :showPlate="showPlate"
        :searchRefString="searchRefString"
        :refSlider="sliderRefZoom"
        @toggleHoverText="toggleHoverText"
      />
    </svg>
  </v-container>
</template>
<script>
import { jsPDF } from "jspdf";
import { mapGetters } from "vuex";
export default {
  name: "SvgDrawing",
  components: {
    DrawingDefs: () => import("../components/DrawingDefs.vue"),
    DrawingGroup: () => import("../components/DrawingGroup.vue"),
    ViewCoordinates: () => import("../components/ViewCoordinates.vue"),
  },
  data() {
    return {
      renderScale: 1,
      netModal: false,
      pinModal: false,
      svgWidth: null,
      viewBoxWidth: null,
      svgScale: null,
      netName: null,
      blockObj: null,
      blockRef: null,
      btnToggle: 1,
      slider: 50,
      sliderScale: 1,
      receptacleColor: [
        "#000000",
        "#0000ff",
        "#ff0000",
        "#00ff00",
        "#840000",
        "#008484",
        "#008400",
      ],
      probeColor: [
        "#ffff00",
        "#00cc99",
        "#ff00ff",
        "#7bffff",
        "#ff7b7b",
        "#ff7bff",
      ],
      guideColor: [
        "#008080",
        "#88540b",
        "#e30022",
        "#3b2f2f",
        "#de3163",
        "#36454F",
      ],
      pressureColor: [
        "#ff7f7f",
        "#77abf4",
        "#ff94c3",
        "#c4d0d0",
        "#21CE9C",
        "#C9BAB0",
      ],
      svgElement: {
        width: null,
        height: null,
        viewBox: null,
      },
      probeElement: [
        {
          x: 2180,
          y: 3430,
          width: 7780,
          height: 7170,
        },
        {
          x: 2200,
          y: 5010,
          width: 7800,
          height: 4000,
        },
        {
          x: 2350,
          y: 1410,
          width: 7400,
          height: 11200,
        },
      ],
      btnRenderSide: 0,
      snack: false,
      showRefs: false,
      searchRefString: null,
      sideToggle: 1,
      showPlate: false,
      refSlider: 50,
    };
  },
  computed: {
    ...mapGetters({
      labelGroup: "svgdrawing/labelGroup",
      testPoints: "testpoints/testPoints",
      guidePins: "guidepins/guidePins",
      pressurePins: "pressurepins/pressurePins",
      project: "projects/selProject",
      fixtureDesign: "fixturedesign/fixtureDesign",
      dutConfigs: "dutconfigs/dutConfigs",
      receptacles: "dutconfigs/receptacles",
      probes: "dutconfigs/probes",
      guidePinAssembly: "dutconfigs/guidePins",
      pressurePinAssembly: "dutconfigs/pressurePins",
      hoverText: "ui/hoverText",
      probeColorGroup: "svgdrawing/probeGroup",
      receptacleColorGroup: "svgdrawing/receptacleGroup",
      guidepinColorGroup: "svgdrawing/guidepinGroup",
      pressurepinColorGroup: "svgdrawing/pressurepinGroup",
    }),
    receptacleGroup() {
      const disabledTP = this.testPoints.filter((element) => !element.enabled);
      const rGroup = this.testPoints
        ? this.testPoints.reduce(
            (group, element) => {
              const finder = group.find((el) => el.pk == element.receptaclePk);
              if (element.enabled) {
                if (!finder) {
                  const receptacle = this.receptacles.find(
                    (item) => item.pk == element.receptaclePk
                  );
                  group.push({
                    pk: element.receptaclePk,
                    description: element.receptacle,
                    part_number: receptacle ? receptacle.part_number : null,
                    count: 1,
                  });
                } else {
                  finder.count++;
                }
              }
              return group;
            },
            disabledTP && disabledTP.length > 0
              ? [
                  {
                    pk: 99,
                    description: "Not populated",
                    part_number: "",
                    count: disabledTP.length,
                  },
                ]
              : []
          )
        : [];
      rGroup.forEach((item, index) => {
        item.color = this.receptacleColor[
          !disabledTP || disabledTP.length == 0 ? index + 1 : index
        ];
      });
      return rGroup;
    },
    probeGroup() {
      const pGroup = this.testPoints
        ? this.testPoints.reduce((group, element) => {
            const finder = group.find((el) => el.pk == element.probePk);
            if (element.enabled) {
              if (!finder) {
                const probe = this.probes.find(
                  (item) => item.pk == element.probePk
                );
                group.push({
                  pk: element.probePk,
                  description: element.probe,
                  part_number: probe ? probe.part_number : null,
                  count: 1,
                });
              } else {
                finder.count++;
              }
            }
            return group;
          }, [])
        : [];
      pGroup.forEach((item, index) => {
        item.color = this.probeColor[index];
      });
      return pGroup;
    },
    guidepinGroup() {
      const gGroup = this.guidePins
        ? this.guidePins.reduce((group, element) => {
            const finder = group.find(
              (el) => el.pk == element.guide_pin_assemblyPk
            );
            if (!finder) {
              const guidepin = this.guidePinAssembly.find(
                (item) => item.pk == element.guide_pin_assemblyPk
              );
              group.push({
                pk: element.guide_pin_assemblyPk,
                description: element.guide_pin_assembly,
                part_number: guidepin ? guidepin.part_number : null,
                count: 1,
              });
            } else {
              finder.count++;
            }
            return group;
          }, [])
        : [];
      gGroup.forEach((item, index) => {
        item.color = this.guideColor[index];
      });
      return gGroup;
    },
    testpointPlot() {
      return this.testPoints
        ? this.testPoints.map((element) => {
            const rcolor = this.receptacleGroup.find(
              (item) => item.pk == element.receptaclePk
            );
            const pcolor = this.probeGroup.find(
              (item) => item.pk == element.probePk
            );
            return {
              ...element,
              rcolor: !element.enabled ? "black" : rcolor ? rcolor.color : null,
              pcolor: !element.enabled ? "white" : pcolor ? pcolor.color : null,
            };
          })
        : [];
    },
    pressurepinGroup() {
      const pGroup = this.pressurePins
        ? this.pressurePins.reduce((group, element) => {
            const finder = group.find(
              (el) => el.pk == element.pressure_pin_assemblyPk
            );
            if (!finder) {
              const pressurepin = this.pressurePinAssembly.find(
                (item) => item.pk == element.pressure_pin_assemblyPk
              );
              group.push({
                pk: element.pressure_pin_assemblyPk,
                description: element.pressure_pin_assembly,
                part_number: pressurepin ? pressurepin.part_number : null,
                count: 1,
              });
            } else {
              finder.count++;
            }
            return group;
          }, [])
        : [];
      pGroup.forEach((item, index) => {
        item.color = this.pressureColor[index];
      });
      return pGroup;
    },
    guidepinPlot() {
      return this.guidePins
        ? this.guidePins.map((element) => {
            const color = this.guidepinGroup.find(
              (item) => item.pk == element.guide_pin_assemblyPk
            );
            return {
              ...element,
              color: color ? color.color : null,
            };
          })
        : [];
    },
    pressurepinPlot() {
      return this.pressurePins
        ? this.pressurePins.map((element) => {
            const color = this.pressurepinGroup.find(
              (item) => item.pk == element.pressure_pin_assemblyPk
            );
            return {
              ...element,
              color: color ? color.color : null,
            };
          })
        : [];
    },
    fixtureConfigId() {
      return this.fixtureDesign?.fixture_config?.pk;
    },
    pdfName() {
      return `${this.project.project_name}-${this.dutConfigs.name}-${
        this.sideToggle == 1 ? "probe" : "pressure"
      }-plate-assembly`;
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    sliderRefZoom() {
      return this.refSlider / 50;
    },
  },
  methods: {
    calculateScale() {
      let rootRect = this.$refs.svg_drawing.getBoundingClientRect();
      this.renderScale = +this.viewBoxWidth / rootRect.width;
    },
    SVGdownloader() {
      this.btnToggle = "1";
      this.sideToggle = "1";

      setTimeout(() => {
        this.downloadSVG(0, "probe", "top");
        this.downloadSVG(1, "probe", "bottom");
        this.btnToggle = "2";
        setTimeout(() => {
          this.downloadSVG(0, "pressure", "top");
          this.downloadSVG(1, "pressure", "bottom");
        }, 100);
      }, 100);
    },
    downloadSVG(index, plate, side) {
      const svgContent = document.getElementsByTagName("svg")[index].outerHTML;
      const blob = new Blob([svgContent], {
        type: "image/svg+xml",
      });
      let url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${this.project.project_name}-${this.dutConfigs.name}-${side}-${plate}-plate-assembly`
      );
      link.click();
    },
    loadImage(ctx, img, canvas) {
      return new Promise((resolve, reject) => {
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          const imgData = canvas.toDataURL("image/png");
          resolve(imgData);
        };

        img.onerror = () => {
          reject(new Error(`Failed to load image`));
        };
      });
    },
    downloadAllImage() {
      this.btnToggle = this.sideToggle = "1";
      setTimeout(async () => {
        let ctx,
          img,
          canvas,
          ctx2,
          img2,
          canvas2,
          ctx3,
          img3,
          canvas3,
          ctx4,
          img4,
          canvas4,
          imgData,
          imgData2,
          imgData3,
          imgData4;

        let svgElement = document.getElementsByTagName("svg")[0];
        const svgSize = svgElement.getBoundingClientRect();
        const svgString = new XMLSerializer().serializeToString(svgElement);
        canvas = document.createElement("canvas");
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;
        ctx = canvas.getContext("2d");
        img = document.createElement("img");
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgString));

        let svgElement2 = document.getElementsByTagName("svg")[1];
        const svgSize2 = svgElement.getBoundingClientRect();
        const svgString2 = new XMLSerializer().serializeToString(svgElement2);
        canvas2 = document.createElement("canvas");
        canvas2.setAttribute("id", "canvas2");
        canvas2.width = svgSize2.width;
        canvas2.height = svgSize2.height;
        ctx2 = canvas2.getContext("2d");
        img2 = document.createElement("img");
        img2.setAttribute(
          "src",
          "data:image/svg+xml;base64," + btoa(svgString2)
        );

        imgData = await this.loadImage(ctx, img, canvas);
        imgData2 = await this.loadImage(ctx2, img2, canvas2);

        this.btnToggle = "2";

        setTimeout(async () => {
          let svgElement3 = document.getElementsByTagName("svg")[0];
          const svgSize3 = svgElement3.getBoundingClientRect();
          const svgString3 = new XMLSerializer().serializeToString(svgElement3);
          canvas3 = document.createElement("canvas");
          canvas3.width = svgSize3.width;
          canvas3.height = svgSize3.height;
          ctx3 = canvas3.getContext("2d");
          img3 = document.createElement("img");
          img3.setAttribute(
            "src",
            "data:image/svg+xml;base64," + btoa(svgString3)
          );

          let svgElement4 = document.getElementsByTagName("svg")[1];
          const svgSize4 = svgElement.getBoundingClientRect();
          const svgString4 = new XMLSerializer().serializeToString(svgElement4);
          canvas4 = document.createElement("canvas");
          canvas4.width = svgSize4.width;
          canvas4.height = svgSize4.height;
          ctx4 = canvas4.getContext("2d");
          img4 = document.createElement("img");
          img4.setAttribute(
            "src",
            "data:image/svg+xml;base64," + btoa(svgString4)
          );
          imgData3 = await this.loadImage(ctx3, img3, canvas3);
          imgData4 = await this.loadImage(ctx4, img4, canvas4);

          let doc = new jsPDF("l", "pt", "a4");
          const imgProps = doc.getImageProperties(imgData);
          const pdfWidth = doc.internal.pageSize.getWidth() - 40;
          const defaultHeight = doc.internal.pageSize.getHeight() - 80;
          const pdfHeight = imgProps.height * (pdfWidth / imgProps.width);
          let trueHeight, trueWidth, widthOffset;
          if (pdfHeight > defaultHeight) {
            trueWidth = pdfWidth * (defaultHeight / pdfHeight);
            trueHeight =
              imgProps.height *
              (defaultHeight / pdfHeight) *
              (pdfWidth / imgProps.width);
            widthOffset = (pdfWidth - trueWidth) / 2;
          } else {
            trueWidth = pdfWidth;
            trueHeight = pdfHeight;
            widthOffset = 0;
          }
          doc.addImage(
            imgData,
            "PNG",
            20 + widthOffset,
            30,
            trueWidth,
            trueHeight
          );
          doc.addPage();
          doc.addImage(
            imgData2,
            "PNG",
            20 + widthOffset,
            30,
            trueWidth,
            trueHeight
          );
          doc.addPage();
          doc.addImage(
            imgData3,
            "PNG",
            20 + widthOffset,
            30,
            trueWidth,
            trueHeight
          );
          doc.addPage();
          doc.addImage(
            imgData4,
            "PNG",
            20 + widthOffset,
            30,
            trueWidth,
            trueHeight
          );
          doc.save(`${this.pdfName}.pdf`);
        }, 100);
      }, 100);
    },
    async downloadImage() {
      let imgData;
      let svgElement = document.getElementsByTagName("svg")[0];
      const svgSize = svgElement.getBoundingClientRect();
      const svgString = new XMLSerializer().serializeToString(svgElement);
      let canvas = document.createElement("canvas");
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;
      const ctx = canvas.getContext("2d");
      const img = document.createElement("img");
      img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgString));

      imgData = await this.loadImage(ctx, img, canvas);

      let doc = new jsPDF("l", "pt", "a4");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth() - 40;
      const defaultHeight = doc.internal.pageSize.getHeight() - 80;
      const pdfHeight = imgProps.height * (pdfWidth / imgProps.width);
      let trueHeight, trueWidth, widthOffset;
      if (pdfHeight > defaultHeight) {
        trueWidth = pdfWidth * (defaultHeight / pdfHeight);
        trueHeight =
          imgProps.height *
          (defaultHeight / pdfHeight) *
          (pdfWidth / imgProps.width);
        widthOffset = (pdfWidth - trueWidth) / 2;
      } else {
        trueWidth = pdfWidth;
        trueHeight = pdfHeight;
        widthOffset = 0;
      }
      doc.addImage(imgData, "PNG", 20 + widthOffset, 30, trueWidth, trueHeight);
      doc.save(`${this.pdfName}.pdf`);
    },
    zoomIn() {
      if (this.slider == 100) return;
      this.slider += 10;
      this.sliderZoom();
    },
    zoomOut() {
      if (this.slider == 0) return;
      this.slider -= 10;
      this.sliderZoom();
    },
    sliderZoom() {
      this.sliderScale = (this.slider - 50) / 10;
      console.log(this.slider, this.sliderScale);
    },
    gotoConfigure() {
      let routeData = this.$router.resolve({
        name: "configure",
        params: { id: this.$route.params.id, did: this.$route.params.dutId },
      });
      window.open(routeData.href, "_blank");
    },
    transformRender(selection) {
      console.log(selection);
      return;
    },
    toggleHoverText(payload) {
      this.snack = payload;
    },
    updateProbe({ probePk, index, event }) {
      if (event.hex == "#FF0000") return;
      this.$store.dispatch("svgdrawing/updateProbeColor", {
        probePk,
        index,
        hex: event.hex,
      });
    },
    updateReceptacle({ receptaclePk, index, event }) {
      if (event.hex == "#FF0000") return;
      this.$store.dispatch("svgdrawing/updateReceptacleColor", {
        receptaclePk,
        index,
        hex: event.hex,
      });
    },
    updateGuidepin({ guidepinPk, index, event }) {
      if (event.hex == "#FF0000") return;
      this.$store.dispatch("svgdrawing/updateGuidepinColor", {
        guidepinPk,
        index,
        hex: event.hex,
      });
    },
    updatePressurepin({ pressurepinPk, index, event }) {
      if (event.hex == "#FF0000") return;
      this.$store.dispatch("svgdrawing/updatePressurepinColor", {
        pressurepinPk,
        index,
        hex: event.hex,
      });
    },
    refZoomIn() {
      if (this.refSlider == 100) return;
      this.refSlider += 10;
    },
    refZoomOut() {
      if (this.refSlider == 0) return;
      this.refSlider -= 10;
    },
  },
  async mounted() {
    this.svgWidth = this.$refs.svg_drawing.getAttribute("width");
    this.viewBoxWidth = this.$refs.svg_drawing
      .getAttribute("viewBox")
      .split(" ")[2];
    this.svgScale = +this.viewBoxWidth / +this.svgWidth;
    this.calculateScale();
    window.addEventListener("resize", this.calculateScale);
    this.svgElement.width = this.$refs.svg_drawing.getAttribute("width");
    this.svgElement.height = this.$refs.svg_drawing.getAttribute("height");
    this.svgElement.viewBox = this.$refs.svg_drawing.getAttribute("viewBox");
    this.$store.dispatch(
      "svgdrawing/updateSVGDrawingTP",
      this.testPoints
        ? this.testPoints.map((element) => {
            const rcolor = this.receptacleGroup.find(
              (item) => item.pk == element.receptaclePk
            );
            const pcolor = this.probeGroup.find(
              (item) => item.pk == element.probePk
            );
            return {
              ...element,
              rcolor: !element.enabled ? "black" : rcolor ? rcolor.color : null,
              pcolor: !element.enabled ? "white" : pcolor ? pcolor.color : null,
            };
          })
        : []
    );
    this.$store.dispatch("svgdrawing/updateSVGDrawingGP", this.guidepinPlot);
    this.$store.dispatch("svgdrawing/updateSVGDrawingPP", this.pressurepinPlot);
    this.$store.dispatch("svgdrawing/updateSVGDrawingPG", this.probeGroup);
    this.$store.dispatch("svgdrawing/updateSVGDrawingRG", this.receptacleGroup);
    this.$store.dispatch("svgdrawing/updateSVGDrawingGPG", this.guidepinGroup);
    this.$store.dispatch(
      "svgdrawing/updateSVGDrawingPPG",
      this.pressurepinGroup
    );
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateScale);
  },
};
</script>
